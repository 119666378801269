<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      本网站将提供一些物品的维修经验，包括步骤，要点以及注意事项。帮助大家变废为宝，节约资源，保护环境。
    </p>
    <div>友情链接：<a href="https://zb.xiuwudi.com" target="_blank">招标采购查询</a> <a href="http://zb.xiuwudi.com" target="_blank">招采信息网</a> <a href="http://zhaobiao.xiuwudi.com" target="_blank">zhaobiao</a> <a href="https://zhaobiao.xiuwudi.com" target="_blank">zhaobiao search</a></div>
    <h3><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备2020044790号</a></h3>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
